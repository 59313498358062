import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import signinImage from '../assets/signup.jpg';

const cookies = new Cookies();

const initialState = {
    fullName: '',
    username: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    avatarURL: '',
}

const Auth = () => {
    const [form, setForm] = useState(initialState);
    const [isSignup, setIsSignup] = useState(true);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { username } = form;
        const avatarURL = '../assets/' + username + '-icon.png';
        const password = 'cornbread';
        const phoneNumber = '';
        const qrcode = window.qrcode;

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "") {
            var URL_location = 'http://localhost:5000/auth';
        } else {
            var URL_location = 'https://chonkyfiregames.com/auth';
        }

        //const { data: { token, userId, hashedPassword, fullName } } 
        
        const res = await axios.post(`${URL_location}/${isSignup ? 'login' : 'login'}`, { // 
            username, password, fullName: username.charAt(0).toUpperCase() + username.substr(1).toLowerCase(), phoneNumber, avatarURL, qrcode,
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
        });
        if (res?.data){
            const { data: { token, userId, hashedPassword, fullName } }  = res;

            cookies.set('token', token);
            cookies.set('username', username);
            cookies.set('fullName', fullName);
            cookies.set('userId', userId);
    
            if(isSignup) {
                cookies.set('phoneNumber', phoneNumber);
                cookies.set('avatarURL', avatarURL);
                cookies.set('hashedPassword', hashedPassword);
                cookies.set('newGame', '1');
            } else {
                cookies.set('newGame', '0');
            }
            window.location.reload();
        }
    }

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup);
    }
    
    const options = [
        { label: 'Choose a faction', value: '' },
        { label: 'Labor', value: 'labor' },
        { label: 'Socialist', value: 'socialist' },
        { label: 'Liberal', value: 'liberal' },
        { label: 'Populist', value: 'populist' },
        { label: 'Traditionalist', value: 'traditionalist' },
        { label: 'Moderate', value: 'moderate' },
    ];

    return (
        <div className="auth__form-container">
            <div className="auth__form-container_fields">
                <div className="auth__form-container_fields-content">
                    <p>{isSignup ? 'Start New Game' : 'Re-Join Game'}</p>
                    <form onSubmit={handleSubmit}>
                        <div className="auth__form-container_fields-content_input">
                            <label htmlFor="username">Faction</label>
                            <select name="username" onChange={handleChange} required>
                                {options.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="auth__form-container_fields-content_button">
                            <button>{isSignup ? "Start New Game" : "Re-Join Game"}</button>
                        </div>
                    </form>
                    <div className="auth__form-container_fields-account">
                        <p>
                            {isSignup
                             ? "Re-join existing game? " 
                             : "Start a new game? "
                             }
                             <span onClick={switchMode}>
                             {isSignup ? 'Re-Join Game' : 'Start New Game'}
                             </span>
                        </p>
                    </div>
                </div> 
            </div>
            <div className="auth__form-container_image">
                <img src={signinImage} alt="join game" />
            </div>
        </div>
    )
}

export default Auth
