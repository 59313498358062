import React, { useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import Cookies from 'universal-cookie';

import { ChannelListContainer, ChannelContainer, Auth } from './components';

import 'stream-chat-react/dist/css/index.css';
import './App.css';

const apiDict = {
    'ATWD': '7uxtmyk3j45k',
    'CBWY': 'hcukdewnxvhk',
    'DDFY': 'pcjt9x44bvwk',
    'KOLA': 'ffnzjxmn57ee',
    'TEST': 'tc2kcamxpvuk',
    'WSUJ': '9dy5e6jpx3wj', 
    'XKDC': '8qqxssg8fkjr'
};
if (window.location.hostname !== 'localhost'){
    const href_arr = window.location.href.split('/');
    if (href_arr[3]=='g' && href_arr[4].length == 4){
       window.qrcode = href_arr[4];
    }
} else {
    window.qrcode = 'TEST';
}

const apiKey = apiDict[window.qrcode];
const cookies = new Cookies();
const authToken = cookies.get("token");

const client = StreamChat.getInstance(apiKey);

if(authToken) {
    client.connectUser({
        id: cookies.get('userId'),
        name: cookies.get('username'),
        fullName: cookies.get('fullName'),
        image: cookies.get('avatarURL'),
        hashedPassword: cookies.get('hashedPassword'),
        phoneNumber: cookies.get('phoneNumber'),
    }, authToken)

    const truncateChannels = async (e) => {
        const filter = { members: { $in: [cookies.get('userId')] } };
        const sort = [{ last_message_at: -1 }];
        
        const channels = await client.queryChannels(filter, sort, {
            watch: true, // this is the default
            state: true,
        });        
        await Promise.all(channels.map(async (channel) => {
            channel.truncate();
        })).catch(function(err) {
            console.log(err.message); // some coding error in handling happened
        });
    }

    if (cookies.get('newGame')=='1' && window.location.hostname !== 'localhost'){
        truncateChannels();
    }
}


const App = () => {
    const [createType, setCreateType] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    if(!authToken) return <Auth />

    return (
        <div className="app__wrapper">
            <Chat client={client} theme="team light">
                <ChannelListContainer 
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                />
                <ChannelContainer 
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    createType={createType}
                />
            </Chat>
        </div>
    );
}

export default App;
